






























































import Bop from "@/models/Bop";

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import OperationGroup from "@/models/OperationGroup";
import BopModel from "@/models/BopModel";
import CoefficientService from "@/services/CoefficientService";
import {CoefficientTypeEnum} from "@/utils/Enums";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import Coefficient from "@/models/Coefficient";
import OperationService from "@/services/OperationService";
import {Guid} from "guid-typescript";
import {roundNumber} from "@/utils/helpers";
import OperationGroupOperationService from "@/services/OperationGroupOperationService";
import Constants from "@/utils/Constants";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
type CaloTuyLine = {
  id: string,
  number: number,
  operations: Array<any>,
  totalPoints: number,
  coefficientSelectedValues: any,
  coefficients: any
};
const INITIALIZED_GLOBAL_COEFFICIENTS:Array<string> = ['K3']
@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue")
  }
})
export default class OperationPrincipalCalTuyStep extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private globalCoefficients: any = {};
  private tableKey: string = "";
  private canShowTypeIsolation: boolean = true;
  private operationIdsToRemove: Array<string> = [];
  private selectedGlobalCoefficients: any = {};
  private operations: Array<any> = [];
  private modifiedOperations: Array<any> = [];
  private caloTyuLines: Array<CaloTuyLine> = [];
  private fieldCoefficient: Array<{ id: string, key: string }> = [];
  private data: Data = {
    columns: [],
    rows: [],
  };
  private canBeLoaded: boolean = false;
  private isFullyLoaded: boolean = false;
  private groupCoefficients: any = {};
  private columnsValidator: any = {}
  private  operationGroupNumber:number = 0;
  private get canBeEditedAfterRealised(): boolean {
    return (
        this.$route.query.isEdit !== undefined &&
        this.$route.query.isEdit === "true"
    );
  }

  private get isEditable(): boolean {
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  }

  private get bopShort(): String {
    return `${
        this.bop.siteName
    } - ${this.bop.bopModelName?.toString().toLowerCase()} ${
        this.bop.bopNumber ? " # " : ""
    }${this.bop.bopNumber ?? ""}`;
  }

  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel;
  }

  private get allTotalPoints() {
    return this.bop.totalOperationPrincipalPoints;
  }

  private get bop() {
    return this.$store.state.bop;
  }

  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }

  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }

  private get language() {
    return this.$i18n.locale;
  }

  private get isBopReadOnly(): boolean {
    return (
        Bop.readOnlyStatuses.includes(this.bop.bopStatusKey) &&
        !this.canBeEditedAfterRealised
    );
  }

  private get isGlobalCoefficientsVisible() {
    return this.globalCoefficients;
  }

  /**
   * ###########
   * methods
   * ###########
   * */
  private async loadCoefficientOptions(coefficientId: string, coefficientType: number): Promise<Array<any>> {
    return (
        await CoefficientOptionService.GetCoefficientOptionsByCoefficient(coefficientId, this.language)
    ).data.map((el: any) => ({
      key: el,
      value: coefficientType == CoefficientTypeEnum.T2 ? el.value : el.name,
    }));
  }
  // operation code: whether the column is visible or not (true|false)
  /**
   * load the saved operation and transforming it from the normal representation of operations with the new representation
   * @private
   */
  private loadOperations() {

    const operationGroups: Array<OperationGroup> = this.bop.operationPrincipalGroups;
    const caloTuyOperationLines: Array<CaloTuyLine> = []
    let columnsValidator = {};
    operationGroups.forEach(og => {

      const operations = this.buildOperationsLine();
      // preparing coefficients
      let coefficientValues: any = {}
      // console.group("test");
      this.fieldCoefficient.forEach((fc) => {
        coefficientValues[fc.key] = {coefficientId: fc.id};
      });
      for (let i in og.selectedGroupCoefficients) {
        if (this.groupCoefficients[i]) {
          const option = this.groupCoefficients[i].find(opt => opt.key.id === og.selectedGroupCoefficients[i]);
          if (option) {
            coefficientValues[i] = option.key;
            coefficientValues[i].coefficientOptionId = coefficientValues[i].id;
          }
        } else {
          coefficientValues[i].value = roundNumber(Number(og.selectedGroupCoefficients[i]));
        }
      }
      // console.groupEnd();
      // console.log( og.operations);
      og.operations.forEach(o => {
        const operationCode = o.operation.code;
        columnsValidator[operationCode] = columnsValidator[operationCode] ? columnsValidator[operationCode] : o.quantity > 0
        operations[operationCode] = o.operation
        operations[operationCode].operationId = o.operation.id
        operations[operationCode].id = o.id
        operations[operationCode].quantity = o.quantity
      });
      const caloTuyLine: CaloTuyLine = {
        id: og.id,
        number: og.number,
        coefficients: {...coefficientValues},
        coefficientSelectedValues: coefficientValues,
        operations,
        totalPoints: 0

      };
      caloTuyOperationLines.push(caloTuyLine)
    });
    this.caloTyuLines = caloTuyOperationLines;
    this.canBeLoaded = true;
    this.columnsValidator = columnsValidator;
  }
  private  validateColumnOperation(operationCode){
    return !this.isBopReadOnly || this.columnsValidator[operationCode];
  }
  private async loadData() {
    if (!this.bop.bopModelId) return;
    // loading global coefficients
    const storeSelectedGlobalCoefficients = this.$store.state.selectedGlobalCoefficients;
    for (let i in this.bop.selectedGlobalCoefficients) {
      this.selectedGlobalCoefficients[i] = this.bop.selectedGlobalCoefficients[i].coefficientOptionId;
      storeSelectedGlobalCoefficients[i] = {...this.bop.selectedGlobalCoefficients[i]}
    }
    this.$store.commit('changeSelectedGlobalCoefficients', storeSelectedGlobalCoefficients);
    const columns: Array<any> = [];
    const coefficients =
        (await CoefficientService.getBopModelCoefficientTypes(this.bop.bopModelId, this.language)).data;
    let globalCoefficients: any = {};
    const fieldCoefficient: Array<any> = [];
    for (let i = 0; i < coefficients.length; i++) {
      const coeff: Coefficient = coefficients[i];
      // we ignore the type 1 coefficients
      if (coeff.coefficientType === CoefficientTypeEnum.T1) continue;

      let coefficientOptions: Array<any> = await this.loadCoefficientOptions(coeff.id, coeff.coefficientType);
      if (coeff.coefficientType === CoefficientTypeEnum.T4) {
        globalCoefficients[coeff.key] = {
          id: coeff.id,
          name: coeff.name,
          coeffKey: coeff.key,
          coefficientType: coeff.coefficientType,
          options: coefficientOptions,
        };
      } else if (coeff.isOperationGroupCoefficient &&
          (coeff.coefficientType === CoefficientTypeEnum.T2 || coeff.coefficientType === CoefficientTypeEnum.T3)) {
        fieldCoefficient.push({key: coeff.key, id: coeff.id});
        columns.push({
          name: "bopDropDown",
          title: coeff.name,
          options: coefficientOptions,
          property: `coefficientSelectedValues.${coeff.key}.coefficientOptionId`,
          coefficient: coeff,
          dataContainer: `coefficients.${coeff.key}`,
          isNotEditable: !this.isEditable,
          textField: 'value',
          valueField: 'key.id'

        });
        this.groupCoefficients[coeff.key] = coefficientOptions;
      } else if (coeff.coefficientType === CoefficientTypeEnum.T0) {
        fieldCoefficient.push({key: coeff.key, id: coeff.id});
        columns.push({
          name: "coefficientEditable",
          title: coeff.name,
          coefficient: coeff,
          property: `coefficientSelectedValues.${coeff.key}.value`,
          dataContainer: `coefficients.${coeff.key}.value`,
          isNotEditable: !this.isEditable,

        });
      }
    }
    this.globalCoefficients = globalCoefficients;
    this.fieldCoefficient = fieldCoefficient;
    // géneration des colonnes
    let totalPointsFormula = "";

    this.loadOperations();

    this.operations = (
        await OperationService.getBopOperationsWithExcpetion(
            this.bop.bopModelId,
            this.language)
    ).data;
    const operationsColumns: Array<any> = []
    this.operations.forEach((operation: any, index: number) => {

      totalPointsFormula += `{operations.${operation.code}.totalPoints} ${this.operations.length - 1 !== index ? '+' : ''}`;
      const operationColumn = {
        name: "quantity",
        hideUnit: true,
        title: operation.name,
        property: `operations.${operation.code}.quantity`,
        isNotEditable: !this.isEditable,
        operationKey: operation.code,
        operationUnit: operation.operationMesureUnitValue,
      };
      //hides all Columns with all its operation quantity = 0
      if(!this.validateColumnOperation(operation.code)) {
        operationColumn['titleClass'] = "hidden";
        operationColumn['dataClass'] = "hidden";
        operationColumn['disabled'] = false;
      }
      columns.push(operationColumn);
    });
    columns.push({
      name: "disabledDynamicInput",
      title: this.$t("bopSetup.total").toString(),
      property: "totalPoints",
      // formula: "{operation.operationUnitValue} * {quantity} * {coefficients.K1.value}",
      formula: totalPointsFormula,
    });
    if(!this.isBopReadOnly && this.isEditable) {
      columns.push({
        name: "actions",
        title: "",
        removeButton: true,
        editButton: false,
        duplicateButton: false,
      });
    }
    this.data.columns = columns;

  }

  private setWait(condition, handler, waitTime = 500) {
    setTimeout(() => {

      if (condition) {
        handler();
      } else {
        this.setWait(condition, handler)
      }
    }, waitTime);
  }

  private setIsLoaded() {
    this.setWait(this.canBeLoaded, () => {
      this.isFullyLoaded = true;
    })
  }

  private onTableMounted() {
    this.setIsLoaded();
  }

  private onTotalPointsChanged(totals) {
    this.$store.commit('changeTotalOperationPrincipalPoints',totals);
  }

  private add() {
    let newLine = this.buildTableLine();
    this.fieldCoefficient.forEach((fc) => {
      newLine.coefficientSelectedValues[fc.key] = {coefficientId: fc.id};
    });
    (<any>this.$refs.table).updatedRows.push(newLine);
    (<any>this.$refs.table).modify(newLine);
  }

  private onSelectedGlobalCoefficientChange(newValue, coeffKey) {
    const storeSelectedGlobalCoefficients = this.$store.state.selectedGlobalCoefficients;
    const selectedCoefficientOption = this.globalCoefficients[coeffKey].options.find(
        (op) => op.key.id === newValue
    )?.key;
    storeSelectedGlobalCoefficients[coeffKey] = selectedCoefficientOption
    this.$store.commit('changeSelectedGlobalCoefficients', storeSelectedGlobalCoefficients);

    // handling bop global coefficients
    this.bop.selectedGlobalCoefficients[coeffKey] ??= {};
    this.bop.selectedGlobalCoefficients[coeffKey].coefficientOptionId = selectedCoefficientOption.id;
    this.bop.selectedGlobalCoefficients[coeffKey].coefficientId = selectedCoefficientOption.coefficientId;
    this.bop.selectedGlobalCoefficients[coeffKey].value = selectedCoefficientOption.value;
    this.$store.commit("changeBop", this.bop);
    this.tableKey = Guid.create().toString();
  }

  private async remove(data){
    const rowIndex = data.rowIndex;
    const rowData = data.rowData;
    try{
      let response = await OperationGroupOperationService.deleteBopOperation(rowData.id,true);
      if(response.status === 200){
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t(
              "bopSetup.operations.deleteSuccessTitle"
          ).toString(),
          text: this.$t("bopSetup.operations.deleteSuccess").toString(),
        });
        (<any>this.$refs.table).removeRow(rowIndex);
        this.modifiedOperations.splice(rowIndex,1);
        this.tableKey = Guid.create().toString();
        // this.bop.operationPrincipalGroups[operationGroupIndex].operations = newOperations;

      }
    }catch{
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t(
            "bopSetup.operations.deleteErrorTitle"
        ).toString(),
        text:this.$t("bopSetup.operations.deleteError").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS
      });
    }
  }

  private buildOperationsLine() {
    return this.operations
        .map(o => ({...o, quantity: 0, totalPoints: 0, operationId: o.id, id: Guid.createEmpty().toString()}))
        .reduce((p, n) => {
          p[n.code] = n;
          return p;
        }, {});
  }

  /**
   * Build a table line
   * @private
   */
  private buildTableLine(): CaloTuyLine {
    let operations = this.buildOperationsLine();
    this.operationGroupNumber = this.operationGroupNumber+1;
    return {
      id: Guid.create().toString(),
      number: this.operationGroupNumber,
      operations,
      totalPoints: 0,
      coefficientSelectedValues: {},
      coefficients: {}
    };
  }

  /**
   * ############
   * Hooks
   * ############
   */
  private created() {
    this.loadData().then(() => {
      INITIALIZED_GLOBAL_COEFFICIENTS.forEach(coeffKey=> {
        console.log((coeffKey));
        console.log(this.globalCoefficients);
        if(!this.selectedGlobalCoefficients[coeffKey]){
          const selectedCoeffId =  this.globalCoefficients[coeffKey].options.sort((a,b)=> a.key.value - b.key.value)[0].key.id
          this.onSelectedGlobalCoefficientChange(selectedCoeffId,coeffKey);
          this.selectedGlobalCoefficients[coeffKey] = selectedCoeffId

        }
      })

    });
  }


  /**
   * ###########
   * watchers
   * ###########
   **/
  @Watch("selectedBopModel.key")
  private async onBopModelChange() {

  }
@Watch("isFullyLoaded")
onFullyLoad(){
    if(this.isFullyLoaded){
      const operationGroups: Array<OperationGroup> = this.bop.operationPrincipalGroups;
      if(!operationGroups.length){
        this.add();
      }
      this.operationGroupNumber = this.caloTyuLines[this.caloTyuLines.length-1].number;
      this.$emit("fully-loaded")
    }
}
  @Watch("modifiedOperations")
  private onModifiedOperationChange() {
    // this.bop.operationPrincipalGroups.forEach(og => og.canBeDeleted = true);
    console.log('operations changed :)');
    // this.$emit('caloTuyauterieOperationsChange', operationGroups);
    this.$emit('caloTuyauterieOperationsChange',this.modifiedOperations);
  }
}
